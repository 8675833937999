<template>
  <v-layout v-if="route.path != '/'" class="!flex h-screen w-screen flex-col overflow-auto">
    <LayoutHeader />
    <div class="flex">
      <SnackbarDefault />
    </div>
    <div class="flex-1 overflow-y-auto">
      <slot />
    </div>
    <AppFooter />
  </v-layout>
  <v-layout v-else>
    <slot />
  </v-layout>
</template>

<script setup lang="ts">
import { useRoute } from 'nuxt/app'
import LayoutHeader from './LayoutHeader.vue'

const route = useRoute()
</script>
