<template>
  <SnackbarSubSnackbarBase
    :data="props.data"
    :title="props.title"
    :text="props.text"
    :type="props.type"
    :dismiss="dismiss"
  >
    <div v-if="type === 'success'" class="grid gap-2.5">
      <div v-if="assetData">
        <a
          :href="assetData.explorerUrl"
          target="_blank"
          class="flex cursor-pointer items-center gap-1 rounded-[10px] border border-solid border-white/10 bg-white/5 px-3 py-2 text-sm leading-none text-white"
        >
          <img class="aspect-square w-4 rounded-full" :src="assetData.chainIcon" alt="" />
          {{ assetData.ticker }}
          <Icon class="ml-auto opacity-50" icon="akar-icons:link-out" />
        </a>
      </div>
      <div v-if="baseAssetData">
        <a
          :href="baseAssetData.explorerUrl"
          target="_blank"
          class="flex cursor-pointer items-center gap-1 rounded-[10px] border border-solid border-white/10 bg-white/5 px-2.5 py-2 text-sm leading-none text-white"
        >
          <img class="aspect-square w-4 rounded-full" :src="baseAssetData.chainIcon" alt="" />
          {{ baseAssetData.ticker }}
          <Icon class="ml-auto opacity-50" icon="akar-icons:link-out" />
        </a>
      </div>
    </div>
  </SnackbarSubSnackbarBase>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue/dist/iconify.js'
import { toRefs } from 'vue'
import { type SnackbarLiquidityData } from '~/store/snackbar'

const props = defineProps<{
  data: SnackbarLiquidityData
  title: string | null
  text: string
  type: string | null
  dismiss: () => void
}>()
const { data, type } = toRefs(props)
const { dismiss } = props
const { assetData, baseAssetData } = data.value
</script>
