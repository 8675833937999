<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <Vue3Snackbar top right :icon-presets="{}" groups>
    <template
      #message-close-icon="{ message, dismiss }: { message: SnackbarMessage; dismiss: () => void }"
    >
      <SubSwap v-if="data?.component === 'swap'" v-bind="data" :dismiss="dismiss" />
      <SubSnackbarBase
        v-else-if="data?.component === 'normal'"
        :data="data"
        :title="message.title"
        :text="message.text"
        :type="message.type"
        :dismiss="dismiss"
      />
      <SubLiquidity
        v-else-if="data?.component === 'liquidity'"
        :data="data"
        :title="message.title"
        :text="message.text"
        :type="message.type"
        :dismiss="dismiss"
      />
    </template>
  </Vue3Snackbar>
</template>

<script setup lang="ts">
import { Vue3Snackbar } from 'vue3-snackbar'
import { storeToRefs } from 'pinia'
import SubSwap from './SubSwap.vue'
import SubLiquidity from './SubLiquidity.vue'
import SubSnackbarBase from './SubSnackbarBase.vue'
import type { SnackbarMessage } from '~/types/snackbar'
import { useSnackbarMessage } from '~/store/snackbar'

const snackbar = useSnackbarMessage()
const { data } = storeToRefs(snackbar)
</script>

<style>
.vue3-snackbar-message:has(
    > .vue3-snackbar-message-wrapper > .vue3-snackbar-message-close > .toba-custom-snackbar
  ) {
  background: none !important;
  border: none !important;
  justify-content: flex-end !important;
}
.vue3-snackbar-message:has(
    > .vue3-snackbar-message-wrapper > .vue3-snackbar-message-close > .toba-custom-snackbar
  )
  .vue3-snackbar-message-wrapper {
  padding: 0;
}
.vue3-snackbar-message:has(
    > .vue3-snackbar-message-wrapper > .vue3-snackbar-message-close > .toba-custom-snackbar
  )
  .vue3-snackbar-message-icon {
  display: none;
}
.vue3-snackbar-message:has(
    > .vue3-snackbar-message-wrapper > .vue3-snackbar-message-close > .toba-custom-snackbar
  )
  .vue3-snackbar-message-title {
  display: none;
}
.vue3-snackbar-message:has(
    > .vue3-snackbar-message-wrapper > .vue3-snackbar-message-close > .toba-custom-snackbar
  )
  .vue3-snackbar-message-additional {
  display: none;
}
.vue3-snackbar-message:has(
    > .vue3-snackbar-message-wrapper > .vue3-snackbar-message-close > .toba-custom-snackbar
  )
  .vue3-snackbar-message-content {
  display: none;
}
.vue3-snackbar-message:has(
    > .vue3-snackbar-message-wrapper > .vue3-snackbar-message-close > .toba-custom-snackbar
  )
  .vue3-snackbar-message-close {
  width: auto;
}
</style>
