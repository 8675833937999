<template>
  <div
    class="toba-custom-snackbar relative flex items-center overflow-hidden rounded-[10px] px-3 py-2.5 backdrop-blur-sm"
    :class="statusClassName"
    @click="status === 'failed' && dismiss()"
  >
    <div class="absolute left-0 top-0 h-full w-full bg-current opacity-10" />
    <div
      v-if="status === 'pending'"
      class="absolute left-0 top-0 h-full bg-[#3e370e] transition-all duration-100"
      :style="{ width: `${estimatedPercent}%` }"
    />
    <div class="z-10 flex items-center gap-1.5 capitalize">
      <div
        v-if="status === 'complete' || status === 'failed'"
        class="rounded-full bg-current text-xs"
      >
        <Icon v-if="status === 'complete'" class="text-white" icon="pajamas:check-xs" />
        <Icon v-if="status === 'failed'" class="text-white" icon="ci:warning" />
      </div>
      <div class="text-xs font-medium">{{ status }}</div>
      <div v-if="status === 'pending'" class="text-xs text-white">{{ time }}</div>
      <img
        class="aspect-square w-[1em] rounded-full text-lg"
        :src="fromAssetLogo || ''"
        alt="From asset"
      />
      <Icon class="text-xs text-white" icon="fluent:arrow-swap-16-filled" />
      <img
        class="aspect-square w-[1em] rounded-full text-lg"
        :src="toAssetLogo || ''"
        alt="To asset"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue/dist/iconify.js'
import { computed, onUnmounted, ref, watch, watchEffect } from 'vue'

import { type SnackbarSwapData, useSnackbarMessage } from '~/store/snackbar'

interface IProps extends SnackbarSwapData {
  dismiss: () => void
}
const props = defineProps<IProps>()

const { clearData } = useSnackbarMessage()

const time = computed<string | null>(() => {
  if (props.timeMs >= 60000) {
    return `~ ${Math.round(props.timeMs / 60000)} mins`
  }
  return `${props.timeMs / 1000} seconds`
})

// TODO
const status = ref('pending')
const currentTimeS = ref(0)
const timeHandler = ref<NodeJS.Timeout | null>(null)

const statusClassName = computed(() => {
  if (status.value === 'pending') {
    return 'text-[#C99D29]'
  }
  if (status.value === 'complete') {
    return 'text-[rgb(78,153,66)] cursor-pointer'
  }
  if (status.value === 'failed') {
    return 'text-[#E95151] cursor-pointer'
  }
  return ''
})

watchEffect(() => {
  if (timeHandler.value !== null) {
    clearTimeout(timeHandler.value)
  }
  timeHandler.value = setTimeout(() => {
    currentTimeS.value += 0.1
    if (props.estimatedTimeS && currentTimeS.value >= props.estimatedTimeS) {
      currentTimeS.value = props.estimatedTimeS
      if (timeHandler.value !== null) {
        clearTimeout(timeHandler.value)
      }
    }
  }, 100)
})

const estimatedPercent = computed(() =>
  props.estimatedTimeS
    ? Math.round((currentTimeS.value / props.estimatedTimeS) * 100 * 10) / 10
    : 0,
)
// TODO
watch(estimatedPercent, (estimatedPercent) => {
  if (estimatedPercent >= 100) {
    if (Math.random() < 0.5) {
      status.value = 'failed'
    } else {
      status.value = 'complete'
      setTimeout(() => {
        props.dismiss()
      }, 5000)
    }
  }
})
onUnmounted(() => {
  clearData()
})
</script>
