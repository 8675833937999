<template>
  <div
    class="toba-custom-snackbar relative flex translate-x-4 flex-col overflow-hidden rounded-l-2xl border border-solid border-white/10 bg-[#141414] text-white backdrop-blur-sm"
  >
    <div class="flex flex-col gap-5 px-6 pb-4 pt-5">
      <div class="flex flex-col gap-y-2.5">
        <div class="flex items-center gap-1">
          <img v-if="type === 'success'" class="w-3.5" src="/images/snackbar-success.svg" />
          <img v-else-if="type === 'error'" class="w-3.5" src="/images/snackbar-error.svg" />
          <img v-else class="w-3.5" src="/images/snackbar-warning.svg" />
          <div class="mr-2.5 text-sm text-white">{{ title }}</div>
          <a class="ml-auto cursor-pointer rounded-lg bg-white/10 p-1.5" @click="dismiss()">
            <Icon icon="iconamoon:sign-times" />
          </a>
        </div>
        <div
          v-if="text"
          class="col-span-2 max-w-52 whitespace-pre-wrap break-words text-xs text-white/50"
        >
          {{ text }}
        </div>
      </div>
      <slot />
    </div>
    <!-- <a
      class="absolute right-2.5 top-2.5 ml-auto cursor-pointer rounded-lg bg-white/10 p-1.5"
      @click="dismiss()"
    >
      <Icon icon="iconamoon:sign-times" />
    </a> -->
    <div class="w-full">
      <div
        class="h-1 transition-all duration-100"
        :class="
          type === 'success' ? 'bg-[#329E73]' : type === 'error' ? 'bg-[#9E3232]' : 'bg-[#C99D29]'
        "
        :style="`width: ${estimatedPercent}%`"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue/dist/iconify.js'
import { computed, onUnmounted, ref, toRefs, watchEffect } from 'vue'
import { type SnackbarBase, useSnackbarMessage } from '~/store/snackbar'

const props = defineProps<{
  data: SnackbarBase
  title: string | null
  text: string
  type: string | null
  dismiss: () => void
}>()
const { data, title, text, type } = toRefs(props)
const { dismiss } = props

const { clearData } = useSnackbarMessage()

const currentTimeS = ref(0)
const timeHandler = ref<NodeJS.Timeout | null>(null)

watchEffect(() => {
  if (timeHandler.value !== null) {
    clearInterval(timeHandler.value)
  }
  timeHandler.value = setInterval(() => {
    currentTimeS.value += 0.1
    if (data.value.estimatedTimeS && currentTimeS.value >= data.value.estimatedTimeS) {
      currentTimeS.value = data.value.estimatedTimeS
      if (timeHandler.value !== null) {
        clearTimeout(timeHandler.value)
        dismiss()
      }
    }
  }, 100)
})

const estimatedPercent = computed(() =>
  data.value.estimatedTimeS
    ? Math.round((currentTimeS.value / data.value.estimatedTimeS) * 100 * 10) / 10
    : 0,
)

onUnmounted(() => {
  clearData()
})
</script>
